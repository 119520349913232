<!-- THIS PAGE NO LONGER EXISTS, FAQ IN FOOTER REDIRECTS TO ZENDESK -->
<template>
  <div class="help-centre">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <h1 style="visibility:hidden; height: 0px; margin: 0">FAQ</h1>
        <h2>Ticket Buying Questions</h2>
        <div
          class="faq-cont"
          v-for="(ques, index) in BQuestions"
          :key="index"
          :class="{ expand: ques.open }"
          @click="ques.open = !ques.open"
        >
          <div class="question">
            <p>
              {{ ques.q }}
            </p>
            <font-awesome-icon icon="angle-down" />
          </div>
          <div class="ans" v-if="ques.open">
            <p class="answer"><strong>A</strong>: {{ ques.ans }}</p>
          </div>
        </div>

        <h2>Ticket Selling Questions</h2>
        <div
          class="faq-cont"
          v-for="(ques, index) in SQuestions"
          :class="{ expand: ques.open }"
          @click="ques.open = !ques.open"
          :key="index"
        >
          <div class="question">
            <p>
              {{ ques.q }}
            </p>
            <font-awesome-icon icon="angle-down" />
          </div>
          <div class="ans" v-if="ques.open">
            <p class="answer"><strong>A</strong>: {{ ques.ans }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
h2 {
  margin-top: 0;
}
.faq-cont {
  padding: 25px 30px;
  border: 1px solid #4a535c;
  font-size: 15px;
  border-radius: 8px;
  margin: 20px 0;
  cursor: pointer;
  transition: all 0.4s linear;
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      font-size: 25px;
      transition: all 0.4s;
      margin-left: 10px;
    }
  }
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  // font-weight:600;
  p.answer {
    font-size: 15px;
    font-weight: 400;
  }

  &.expand {
    // border: 0px;
    background: var(--dashboard-tiles);

    svg {
      transform: rotate(180deg);
      transition: all 0.4s;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 5%;

    h3 {
      font-size: 15px;
    }
    p {
      font-size: 12px;
      line-height: 180%;
    }
  }
}
//
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
// import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

library.add(faAngleDown);

export default {
  name: "help-centre",
  components: {
    FontAwesomeIcon
  },
  head() {
    return {
      title: "Manage Events & Sell Tickets Online •  FAQs • INCREDEVENT",
      meta: [
        {
          name: "description",
          content:
            "Save money and time with INCREDEVENT's industry-leading event planner features. Our FAQs cover everything from advertising events to buying tickets."
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion"
        },
        {
          name: "author",
          content: "INCREDEVENT"
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0"
        }
      ]
    };
  },
  data() {
    return {
      SQuestions: [
        {
          q: "How much does it cost to sell tickets on your website?",
          ans:
            "It's free to list your event on incredevent.com and it only costs you money when you start to make money. Our fee for selling tickets for you is 4% of total sales plus a small INCREDEVENT fee starting at $1.50! Our fees include credit card processing fees! Watch out, our competitors often sneak these fees in on top of their advertised rates. You can pass these fees on to your customers by adding a service fee to your tickets in the" +
            ' "ADD FEES" column when making your event.',
          open: false
        },
        {
          q: "How long does it take to add an event to incredevent.com?",
          ans:
            "It takes less than 10 minutes to add an event. Once you've listed a few times, you'll likely get events up and running within 5 minutes.",
          open: false
        },
        {
          q: "How do I claim my ticket earnings?",
          ans:
            "INCREDEVENT directly deposits your funds into your bank account. To receive payouts just go to the brand settings page and fill in your banking details. Due to the volume of transactions, we can no longer offer eTransfer.",
          open: false
        },
        {
          q: "How long does it take to receive my ticket earnings?",
          ans:
            "A standard INCREDEVENT event (without Express Pay, which is available to our sellers with a proven track record) will be paid out the second Monday after your event. The reason we take some time with payouts is because of increasing fraud online. While we have industry leading fraud protection, fraud does happen. This time period allows for 99% of those who have had fraud perpetrated against them to report the charges. It is much less complicated to assist with fraud prior to paying out for your event; both for INCREDEVENT and you as the seller. At times we do pay out earlier, however this time period should be the expectation.",
          open: false
        },
        {
          q: "Can I cancel my event?",
          ans:
            "Yes, if tickets have not been sold then you can cancel your event instantly. If you cancel an event with sold tickets, all remaining unsold tickets will be removed and the event will be suspended until all refunds are issued. We will contact you to confirm cancellation before we start the refund procedure.",
          open: false
        }
      ],
      BQuestions: [
        {
          q:
            "I See a Charge on My Statement But Didn't Get a Ticket. What Do I Do?",
          ans:
            "When you were purchasing your ticket you may have noticed an error message, likely related to incorrect address information. When you put in that incorrect information and attempt to run a charge your bank will hold on to that charge as a pre-authorization. INCREDEVENT does not receive those funds. This is not the case with all banks, but many have made this practice policy. We have strict security parameters for address information as this is the #1 protection we have found against fraud. If you attempted to buy tickets 3 times and got the information correct on the 3rd try then you will see 3 charges. The first 2 will fall off of your statement as you bank confirms the information on their end. The third charge will be processed by us and you will receive tickets. If you attempted to buy tickets 3 times and all 3 times you got the information incorrect then you will see 3 charges that will fall off your statement once the bank has confirmed the details. To be clear, INCREDEVENT does not receive pre-authorizations, and there is nothing we can do about these charges. Our suggestion would be to call your bank to discuss the matter with them, and get your updated card information to avoid this issue on all websites and apps in the future.",
          open: false
        },
        {
          q: "I Bought a Ticket But I Can't Print it, What Can I Do?",
          ans:
            "You can use your mobile device for entry, and some ticket sellers will accept the ticket number and validation code together for entry. However, it's always best to contact the event organizer to be sure. You can also login to your account anywhere and print your tickets from the" +
            ' "Ticket History" section. Try your local library, or a friend/family member with a printer.',
          open: false
        },
        {
          q: "How Do You Come Up With Your Ticket Prices and Fees?",
          ans:
            "Ticket prices are determined by the event planner. INCREDEVENT has no control over ticket prices. In regards to fees, we offer some of the lowest ticket fees online. Our fees aren't just going straight to our fancy wardrobe either. Fees cover processing, merchant accounts (such as Visa or Mastercard), and secure upgrades to the site.",
          open: false
        },
        {
          q: "Where Do I Find My Receipt?",
          ans:
            "We love trees and clean air, so we don't send a separate receipt for your purchase. Your ticket is your receipt, and at any time you can download every ticket you've" +
            ' ever purchased from "Ticket History".',
          open: false
        },
        // {
        //   q: "I Can't Seem To Login, What Am I Doing Wrong?",
        //   ans:
        //     'If you are trying to login with your username and password and it is not working, it is possible that the first time you signed up was through Facebook. In this case try using Facebook to login. You can also dial in the email you think you used and hit "Forgot Password".' +
        //     " If both of these techniques fail, contact us. We'll gladly help you out.",
        //   open: false,
        // },
        {
          q:
            "I'm not getting emails from incredevent.com, where are my tickets?",
          ans:
            "Some email programs will send our emails to your junk/spam folder. The best thing to do is to add 411@incredevent.com to your address book and your white list. If your tickets aren't in junk, please let us know. We will send you a copy. Please be advised; tickets can only be scanned at the event once, so printing more than one copy of your ticket will not allow more people in.",
          open: false
        },
        {
          q: "What is an e-ticket and where is my real ticket?",
          ans:
            'When you purchase tickets on incredevent.com your "E-ticket" is automatically emailed to you. An E-ticket is an electronic version of your ticket. This is your official ticket and will grant you access to the event. You can print the ticket or some event organizer may let you use your mobile device for entry or even accept the ticket number and validation code together for entry. However, ' +
            "it's always best to contact the event organizer to be sure.",
          open: false
        }
      ]
    };
  },
  created() {
    this.$store.commit("setTitle", "FAQ");
  }
};
</script>
